
























































































































































import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import Organization from '../../../../../store/models/Organization'
import Account, { AccountType } from '../../../../../store/models/Account'

export default Vue.extend({
  data() {
    return {
      accountBalanceAmount: 0,
      newDeadlineModalActive: false,
      columns: [
        { label: 'N°', field: '_index' },
        { label: 'Échéance', field: 'date' },
        { label: 'Capital', field: 'capital', currency: true },
        { label: 'Interêts', field: 'interest', currency: true },
        { label: 'Mensualité', field: 'monthlyAmount', currency: true },
        { label: 'Actions', field: '_actions', sortable: false }
      ],
      amount: 0,
      deadlines: [],
      newDeadline: {
        date: new Date(),
        dateStr: moment().format('DD/MM/YYYY'),
        capital: 0,
        interest: 0,
        monthlyAmount: 0
      },
      editingDeadline: -1
    }
  },

  computed: {
    ...mapGetters('entities/credit_line_provisions', [
      'newProvision',
      'newProvisionDeadlines'
    ]),
    ...mapGetters('product', ['getCurrentProduct']),
    ...mapGetters('auth', ['user']),

    organization() {
      return Organization.loggedIn()
    },

    amountValidation(this: any) {
      if (!this.getCurrentProduct.manageFinancialFlow) return { minValue: 0 }
      return {
        balanceValue: this.accountBalanceAmount
      }
    },

    otm(): string {
      return this.$route.params.otm
    },

    totalCapital(this: any): number {
      return this.deadlines.reduce(
        (total: number, item: any) => total + item.capital,
        0
      )
    },

    totalInterest(this: any): number {
      return this.deadlines.reduce(
        (total: number, item: any) => total + item.interest,
        0
      )
    },

    monthlyAmountTotal(this: any): number {
      return this.deadlines.reduce(
        (total: number, item: any) => total + item.monthlyAmount,
        0
      )
    }
  },

  watch: {
    'newDeadline.date'(this: any, newDate) {
      this.newDeadline.dateStr = moment(newDate).format('DD/MM/YYYY')
    },

    'newDeadline.capital'(this: any, value) {
      this.newDeadline.monthlyAmount = value + this.newDeadline.interest
    },

    'newDeadline.interest'(this: any, value) {
      this.newDeadline.monthlyAmount = value + this.newDeadline.capital
    },

    $route: {
      immediate: true,
      handler() {
        this.amount =
          this.otm === 'mtn'
            ? this.newProvision.mtnAmount
            : this.newProvision.moovAmount
        this.deadlines = this.newProvisionDeadlines(this.otm)
      }
    }
  },

  methods: {
    ...mapActions('entities/credit_line_provisions', [
      'updateNewProvisionAmount',
      'generateNewProvisionDeadlines',
      'editNewProvisionDeadline',
      'removeNewProvisionDeadline'
    ]),

    resetNewDeadline(this: any) {
      this.newDeadline = {
        date: new Date(),
        dateStr: moment().format('DD/MM/YYYY'),
        capital: 0,
        interest: 0,
        monthlyAmount: 0
      }
    },

    async onAmountChanged(amount: number) {
      await this.updateNewProvisionAmount({
        otm: this.otm,
        amount: Number(amount)
      })

      this.deadlines = await this.generateNewProvisionDeadlines(this.otm)
    },

    onNextClicked(this: any) {
      this.$refs.amountValidator.validate().then((isValid: boolean) => {
        if (isValid) {
          if (this.otm === 'mtn') {
            this.$router.push({
              name: 'creditlines.create.deadlines',
              params: { otm: 'moov' }
            })
          } else {
            this.$router.push({
              name: 'creditlines.create.summary'
            })
          }
        }
      })
    },

    onAddDeadlineClick(this: any) {
      this.$refs.deadlineValidator.validate().then(async (isValid: boolean) => {
        if (isValid) {
          await this.editNewProvisionDeadline({
            index: this.editingDeadline,
            data: {
              otm: this.otm,
              ...this.newDeadline
            }
          })

          this.newDeadlineModalActive = false
          this.resetNewDeadline()
          this.deadlines = this.newProvisionDeadlines(this.otm)
          this.amount =
            this.otm === 'mtn'
              ? this.newProvision.mtnAmount
              : this.newProvision.moovAmount
          this.$forceUpdate()
        }
      })
    },

    onEditDeadlineClick(this: any, index: number) {
      this.editingDeadline = index
      this.newDeadlineModalActive = true
      this.newDeadline = this.deadlines[index]
      this.newDeadline.dateStr = moment(this.newDeadline.date).format(
        'DD/MM/YYYY'
      )
    }
  },

  async mounted() {
    await Account.api().fetchAll()
    const account = Account.query()
      .where(
        (value: any) =>
          value.type == AccountType.DISBURSEMENT &&
          value.otm == this.otm &&
          value.owner == this.user.organizationId &&
          value.productId == this.getCurrentProduct.id
      )
      .get()[0]

    this.accountBalanceAmount = (
      await Account.api().accountBalance(account.key)
    ).response.data
  }
})
